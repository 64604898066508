import LinearProgressWithLabel from "../../components/dashboard/linear-progress-with-label";

const TicketByStatut = (props) => {
  const { data, totalTickets } = props;

  return (
    <>
      <h2>Nombre de tickets par statut</h2>
      <div className="contentDashboard">
        {Object.keys(data)?.map((key, index) => {
          return (
            <div
              className={"item " + data[key]?.class}
              key={index}
              style={{ display: "flex" }}
            >
              <div style={{ marginRight: "15px", marginBottom: "15px" }}>
                <div className="ticketStatus">{key}</div>
                <div className="ticketNumber">
                  {data[key]?.data?.length} ticket{data[key]?.data?.length>1? "s":''}
                </div>
              </div>
              <LinearProgressWithLabel
                className="progressBar"
                style={{ color: data[key]?.color }}
                value={
                  totalTickets === 0
                    ? 0
                    : parseInt((data[key]?.data?.length / totalTickets) * 100)
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
export default TicketByStatut;
