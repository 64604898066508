/**
 * MainLayout
 * Création : 15/03/2023
 * Layout principal une fois connecté
 * Historique
 *      DD/MM/YYYY   log
 */
import { connect } from "react-redux";
import mapStateToProps from "../redux/mapStateToProps";
import mapDispatchToProps from "../redux/mapDispatchToProps";

import { useEffect } from "react";
/*
import Box from '@mui/material/Box';

import './main-layout.scss';
*/

import Header from "./header-layout";
import Content from "./content-layout";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

//TODO appel api getMe (pour remplir le header/paramètres/mon profil + sidebar/logocompany)
//TODO appel api historique de navigation (pour remplir le sidebar)
const MainLayout = (props) => {
  const {
    getMe,
    user,
    getOneCompanyMaterials,
    getAllContactsByCompany,
    getConfigData,
  } = props;

  useEffect(() => {
    axios.interceptors.request.use(
      function(request){
        const token = localStorage.getItem("clientmmgmt");
        if (token){
          const decodeJwt = jwtDecode(token);
          if ((Date.now()/1000) > decodeJwt.exp) {//jwt expire 
            console.log('token expire');
            localStorage.removeItem("clientmmgmt");
            window.location.reload();
          }
        }

        return request;
      }
    );
    axios.interceptors.response.use(
      function (response) {
        // ajaxRequests?.push(response?.url);
        // spinning start to show
        // console.log("ajaxRequests", ajaxRequests);

        // console.log(response);

        //les urls commençant par silentUrls ne déclenchent pas le loader

        return response;
      },
      function (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("clientmmgmt");
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );
    getConfigData();
    //console.log("layout/main-layout.js", 'getMe called...')
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //console.log(user)
    if (
      user?.role &&
      user?.role?.type !== "admin" &&
      user?.role?.type !== "client"
    ) {
      // setLoginErrorMessage(
      //   "Vous n'avez pas le droit d'utiliser cette application, seul les rôles admin et client sont autorisés!"
      // );
      localStorage.setItem(
        "client:error",
        "Vous n'avez pas le droit d'utiliser cette application, seul les rôles admin et client sont autorisés!"
      );
      localStorage.removeItem("clientmmgmt");
      window.location.reload();
    }
    if (user?.company) {
      //console.log(user);
      getOneCompanyMaterials(user.company.toString());
      getAllContactsByCompany(user.company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <section className="corps">
      <Header currentUser={user} />
      {user && <Content currentUser={user} />}
    </section>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
