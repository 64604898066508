const TicketByCompte = (props) => {
  const { data } = props;

  return (
    <>
      <h2>Nombre de tickets par compte</h2>
      <div className="list contentDashboard">
        {Object.keys(data)?.map((key, index) => {
          return (
            <div key={index} className="item">
              <div>{key}</div>
              <div>
                <span className="nbTicket">{data[key]?.data?.length}</span>{" "}
                ticket{data[key]?.data?.length>1? "s" : ''}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default TicketByCompte;
