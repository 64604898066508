/**
 * UploadableImage
 * Image editable et enregistre l'image uploadée en faisant appel à uploadFile,
 * puis enregistre dans Document et fait appel à props.param.set.func
 *
 */
import { useEffect, useState } from "react";

import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";

import { Alert, Avatar } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ReactComponent as Upload } from "../../assets/images/icon-upload.svg";

import "./uploadable-image.scss";

const UploadableImage = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadHasErr, setUploadHasErr] = useState(false);
  const { param, uploadFile, addDocument } = props;
  const maxSize = 2 /* en Mo */ * 1024 * 1024;
  const maxWidth = 1600;

  useEffect(() => {
    if (!imageLoaded && param.handler.get.funcParam) {
      //console.log(param.handler.get.func)
      props[param.handler.get.func](param.handler.get.funcParam);
    }
  }, [param]);

  useEffect(() => {
    setImageLoaded(true);
    const data = props[param.handler.get.data];
    if (data) {
      const image = data[param.handler.get.imageField];
      const file = image?.file?.[0];
      //console.log('get data.file', file)
      if (file) {
        const size = param.style.size ? param.style.size : "thumbnail";
        const filePath = file?.formats ? file.formats[size].url : "";

        setImagePath(filePath); //TODO check
      }
    }
  }, [props[param.handler.get.data]]);

  const handleFileChange = (e) => {
    console.log("handleFileChange", e);
    const files = e.target.files;

    if (files && files.length > 0) {
      setLoading(true);

      let formData = new FormData();
      formData.append("files", files[0]);
      if (files[0]?.type.toLowerCase().includes("image"))
        setUploadHasErr(false);
      else setUploadHasErr(true);
      var img;
      img = document.createElement("img");
      img.onload = function () {
        console.log({ width: img.width, height: img.height });
        if (img?.width > maxWidth && files.size > maxSize) {
          setUploadHasErr(true);
          // console.log("eeeee")
        } else {
          // console.log("aaaaa")
          setUploadHasErr(false);
          uploadFile(formData).then((res) => {
            console.log("uploadFile.res", res);

            const doc = {
              name: res.data[0].name,
              document_type: 1,
              file: res.data[0].id,
            };
            addDocument(doc).then((resAddDoc) => {
              console.log("addDocument.resAddDoc", resAddDoc);

              let data = { id: param.handler.get.funcParam };
              data[param.handler.get.imageField] = resAddDoc.data;

              console.log("data to pass", data);

              console.log(param.handler.set.func)
              props[param.handler.set.func](data).then((resUpdate) => {
                console.log("resUpdate", resUpdate);
                props[param.handler.get.func](param.handler.get.funcParam);

                setLoading(false);
              });
            });
          });
        }
      };
      img.src = URL.createObjectURL(files[0]);
    }
  };

  return (
    <div className="u-image">
      <input
        type="file"
        id="uploadFile"
        accept={param.accept}
        onChange={handleFileChange}
        title="Editer l'image"
      />
      {imagePath && (
        <>
          <div className="edit">
            <Avatar>
              <ModeEditIcon />
              <span
                className={`uploadLogoImgInfo ${uploadHasErr ? "error" : ""}`}
              >
                &lt;{maxWidth}px et &lt;{maxSize / 1024 / 1024}Mo
              </span>{" "}
            </Avatar>
          </div>
          <img
            src={`${process.env.REACT_APP_PROXY}${imagePath}`}
            alt=""
            width={"100%"}
            height={"100%"}
          />
        </>
      )}
      <button className="btn primary">
        <Upload />
        Importer votre logo{" "}
        <span className={`uploadLogoImgInfo ${uploadHasErr ? "error" : ""}`}>
          {" "}
          <br />
          &lt;{maxWidth}px et &lt;{maxSize / 1024 / 1024}Mo
        </span>
      </button>

      {uploadHasErr && <Alert severity="error">Format de l’image n’est pas adapté</Alert>}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadableImage);
