import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

// TO do CSS
const ErrorDialog = ({ title, children, open, setOpen, onClose }) => {
  const handleClose = () => {
    setOpen(false);
    onClose();
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
      className="DeletePopup"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent className="deleteContent">
        <span className="icon-danger">
          <ReportProblemIcon fontSize="large"/>
        </span>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          className="cancelBtn"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
