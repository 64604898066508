import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import mapStateToProps from "../../redux/mapStateToProps";
import mapDispatchToProps from "../../redux/mapDispatchToProps";
import { Tooltip } from "@mui/material";
import { getLocaleDateTime } from "../../core/utils";
import SortIcon from "@mui/icons-material/Sort";
import UploadableImage from "../../components/image/uploadable-image";
import { useLocation } from "react-router-dom";
//import { getAllTicketsSync } from "../../redux/actions/ticketAction";

const RecentItems = ({
  ticketConsultations,
  user,
  dataConfig,
  company,
  onSelectTicket,
  onClickRetract,
  isShow,
  ticketsNotFiltered,
  getTicketConsultationsByUser,
  needRefresh,
}) => {
  const logoCompanyParam = {
    handler: {
      get: {
        func: "getOneCompany",
        funcParam: user?.company,
        data: "company",
        imageField: "logo",
      },
      set: { func: "updateCompany" },
    },
    style: { type: "avatar", size: "thumbnail" },
    accept: ".jpg, .jpeg, .png",
  };
  const RECENT_ITEMS_LENGTH = dataConfig?.CLIENT_RECENT_ITEMS_MAX_LENGTH
    ? dataConfig?.CLIENT_RECENT_ITEMS_MAX_LENGTH
    : 12;

  const [items, setItems] = useState([]);
  const location = useLocation();

  useEffect(()=>{
    if (user?.id) {
      getTicketConsultationsByUser(user?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[needRefresh,user,location])

  const handleSelectTicket = (ticket) => {
    if (onSelectTicket && typeof onSelectTicket === "function")
      onSelectTicket(ticket);
  };
  const handleClickRetract = () => {
    if (onClickRetract && typeof onClickRetract === "function")
      onClickRetract();
  };

  const loadRecentItems = () => {
      const tickets = ticketsNotFiltered;
      // console.log(tickets)
      // console.log(ticketsNotFiltered)
      if (tickets?.length && tickets?.length > 0) {
        const orderedConsultations = ticketConsultations
          ?.slice()
          ?.sort((a, b) => {
            return new Date(b?.created_at) - new Date(a?.created_at);
          });
        let filteredConsultationsticketID = [];

        orderedConsultations?.forEach((consultation) => {
          if (
            !filteredConsultationsticketID?.includes(consultation?.ticket?.id)
          ) {
            filteredConsultationsticketID = [
              ...filteredConsultationsticketID,
              consultation?.ticket?.id,
            ];
          }
        });

        const recentlyConsultedTickets = filteredConsultationsticketID.map(
          (x) => {
            //console.log(x);
            const consultation = orderedConsultations?.filter(
              (c) => c?.ticket?.id === x
            )[0];
            return {
              ...consultation?.ticket,
              storyDate: consultation?.created_at,
            };
          }
        );

        const createdTickets = tickets
          ?.filter((t) => {
            // console.log('t?.lastUpdatedBy',t?.lastUpdatedBy);
            // console.log('user?.id', user?.id);
            return t?.creator_user?.id === user?.id;
          })
          .map((t) => ({
            ...t,
            storyDate: t?.created_at,
          }));
        //console.log("createdTickets", createdTickets);
        const updatedTickets = tickets
          ?.filter((t) => t?.lastUpdatedBy?.id === user?.id)
          ?.map((t) => ({
            ...t,
            storyDate: t?.updated_at,
          }));
        //console.log("updatedTickets", updatedTickets);

        const combined = recentlyConsultedTickets
          ?.concat(createdTickets)
          ?.concat(updatedTickets)
          ?.slice()
          ?.sort((a, b) => {
            return new Date(b?.storyDate) - new Date(a?.storyDate);
          });

        let resultID = [];
        combined?.forEach((ticket) => {
          if (!resultID.includes(ticket?.id)) resultID.push(ticket?.id);
        });

        const length =
          RECENT_ITEMS_LENGTH <= resultID?.length
            ? RECENT_ITEMS_LENGTH
            : resultID?.length;
        setItems(
          [...Array(length)].map((v, i) => {
            //console.log(resultID[i]);
            return tickets.filter((t) => t?.id === resultID[i])[0];
          })
        );
      }
    
  };
  // console.log(ticketConsultations);

  useEffect(() => {
    if (
      ticketConsultations?.length > 0 &&
      user?.id
      // &&
      // dataConfig?.CLIENT_RECENT_ITEMS_MAX_LENGTH
    ) {
      loadRecentItems();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [ticketConsultations, ticketsNotFiltered, user, dataConfig]);

  return (
    <div className="leftCol">
      <span className="retractBtn formobile">
        <a href="#;">
          <button
            className={`show ${isShow ? "hide" : ""}`}
            onClick={handleClickRetract}
          >
            <SortIcon />
          </button>
        </a>
      </span>
      <div className="company">
        <div className="logo">
          <UploadableImage param={logoCompanyParam} />
        </div>
        <div className="compName">
          <label>{company?.name}</label>
        </div>
      </div>
      {items && items?.length > 0 && (
        <div className="recent">
          <h2>Eléments récents</h2>
          <ul>
            {items.map((ticket, i) => {
              //console.log(ticket);
              if (ticket?.id)
                return (
                  <Tooltip
                    key={i}
                    title={
                      <div>
                        <span className="font-bold">Objet :</span>
                        {` ${ticket?.objet}`.substring(0, 36) +
                          `..., ${getLocaleDateTime(
                            ticket?.created_at,
                            user?.timezone
                          )}`}
                      </div>
                    }
                    className=""
                  >
                    <li
                      onClick={() => {
                        handleSelectTicket(ticket);
                      }}
                    >
                      #{ticket?.id} : {ticket?.objet}
                    </li>
                  </Tooltip>
                );
              else return null;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentItems);
